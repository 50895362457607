<template>
  <div id="Login" class="login fullheight" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer fluid class="fullheight"> 
      <CRow class="align-start align-md-center fullheight">
        <CCol cols="12" md="12" style="margin-top: 100px !important">
          <div class="login__container" style="max-width: 600px !important; margin: auto; padding: 30px 20px;">
            <div class="login__inner" style="max-width: 500px !important">
              <div class="login__image">
                <img src="/img/logotipo.png" alt="Logo Oscuro"> <br><br> Para completar el proceso debes definir una nueva contraseña de acceso.<br><br>
              </div>
              <div class="login__form">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      :value.sync="$v.form.email.$model"
                      label="Correo Electrónico"
                      placeholder=""
                      disabled
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      :value.sync="$v.form.password.$model"
                      :isValid="checkIfValid('password')"
                      label="Contraseña"
                      type="password"
                      placeholder=""
                      invalidFeedback="Este campo es requerido con al menos 8 caracteres."
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      :value.sync="$v.form.password2.$model"
                      :isValid="checkIfValid('password2')"
                      label="Confirmar Contraseña"
                      type="password"
                      placeholder=""
                      invalidFeedback="Este campo es requerido con al menos 8 caracteres."
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CButton type="button" color="primary" style="width: 100%" @click="active">ASIGNAR NUEVA CONTRASEÑA</CButton>
                  </CCol>
                </CRow>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>

import router from '../../../router/index'
import login from '../../../services/login';
import general_ws from '../../../services/general';
import store from '../../../store'

import axios from 'axios'

import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"

export default {
    name: 'RecoveryForm',
    components: {
      
    },
    data () {
        return {
          form: {
              id: 0,
              code: '',
              email: '',
              password: '',
              password2: ''

          },
          user: false,
          account: false,
        }
    },
    mounted: async function() {
      this.form.id = await this.$route.params.id;
      this.form.code = await this.$route.params.code;

      let response = await login.getUser(this.form.id); 

      if(response.type == "success"){
        this.user = response.data.user;
        this.account = response.data.account;

        this.form.email = this.user.email;
      }
    },
    methods: {
        async active () {
            this.$v.$touch();

            if (this.isValid) {
                if(!this.validatePasswords(this.form.password, this.form.password2)){
                  return;
                }

                this.submitted = true;
                
                let response = await login.recoveryPassword(this.form);
                
                if(response.type == "success"){
                  let params = {
                    email: this.form.email,
                    password: this.form.password,
                    device_name: navigator.userAgent
                  };

                  let response2 = await login.login(params);

                  if(response2.type == "success"){
                    let data = response2.data;

                    localStorage.setItem("token", data.api_token);

                    axios.defaults.headers.common['api-token'] = data.api_token;

                    window.location.href = "/#/welcome";
                  }
                }
                else{
                  alert(response.message);
                }
            }
            
        },
        validatePasswords (password, password2) {
            if(password.length >= 0 && password.length < 8){
              alert("La contraseña debe tener al menos 8 caracteres");
              return false;
            }

            if(password != password2){
              alert("Las contraseñas no coinciden");
              return false;
            }

            return true;
        },
        checkIfValid (fieldName) {
            const field = this.$v.form[fieldName]
            if (!field.$dirty) {
                return null
            }

            if(fieldName == "email"){
              if(field.$model == "raalveco@gmail.com"){
                this.show_password = true;
              }
            }
            
            return !(field.$invalid)
        },
        showToast(type, message){
            store.commit('toast', {color: type, message: message});
        },
        loading(){
            store.commit('loading');
        },
        loaded(){
            store.commit('loaded');
        }
    },
    computed: {
        formString () { return JSON.stringify(this.form, null, 4) },
        isValid () { return !this.$v.form.$invalid },
        isDirty () { return this.$v.form.$anyDirty },
    },
    mixins: [validationMixin],
    validations: {
        form: {
            id: {},
            code: {},
            email: {},
            password: {
                required,
                minLength: minLength(8)
            },
            password2: {
                required,
                minLength: minLength(8)
            }
        }
    },
}
</script>